import React, { useEffect, useState, useRef, useContext } from "react";
import { BrowserView, MobileView, isBrowser, isMobile } from "react-device-detect";
import Container from "react-bootstrap/Container";
import DataContext from "../Elements/eventContext";
import LoginModal from "../Modals/login_modal";
import CartModal from "../Modals/cart_modal";
import Modal from "react-bootstrap/Modal";
import MenuModal from "../Modals/menu_modal";
import { ApiService } from "../../Components/Services/apiservices";
import HeaderMenu from "../Elements/header_menu";
import sessionCartData from "../../Components/Elements/cart_session_data";
import SpinnerLoader from "../Elements/spinner_loader";
import multiCurrency from "../../Components/Elements/multi_currrency";
import QuickViewModal from "../Modals/quick_view_modal";
function Header({ cartCount = 0, state = "" }) {
  const didMountRef = useRef(true);
  const contextValues = useContext(DataContext)
  const dataArray = sessionCartData();
  const [parsedCartSession, setparsedCartSession] = useState(dataArray[1]);
  const [show, setShow] = useState(false);
  const handleShow = () => setShow(true);
  const handleChildData = (status) => {
    setShow(status);
  };
  const [spinnerLoading, setSpinnerLoading] = useState(true);
  const [showCart, setShowCart] = useState(false);
  const handleShowCart = () => setShowCart(true);
  const handleChildCartData = (status) => {
    setShowCart(status);
  };
  const handleClose = () => {
    setShowCart(false);
  };

  const [showMenuCart, setShowMenuCart] = useState(false);
  const handleShowMenuCart = () => setShowMenuCart(true);
  const handleChildMenuCartData = (status) => {
    setShowMenuCart(status);
  };
  const handleMenuClose = () => {
    setShowMenuCart(false);
  };

  const [setSession, SetSession] = useState("");
  const [searchTerm, setSearchTerm] = useState("");
  const [searchResults, setSearchResults] = useState([]);
  const [headerdata, setHeaderData] = useState({});
  const [settingData, setSettingData] = useState({});
  const [settingImagePath, setSettingImagePath] = useState("");

  useEffect(() => {
    if (didMountRef.current) {
      contextValues.setAddressSession(dataArray[0])
      contextValues.setCouponSession(dataArray[2])
      if (localStorage.getItem("USER_TOKEN")) {
        cartSessionData();
      } else {
        contextValues.setCartSessionData(dataArray[1])
        contextValues.setCartCount(dataArray[1].length)
        contextValues.setCartSummary(dataArray[3])
      }
      handleChangeCurrency();
      getheaderdata();
      getSettingsData();
      SetSession(localStorage.getItem("USER_TOKEN"));
    }
    didMountRef.current = false;
    if (isBrowser) {
      const handleScroll = () => {
        if (window.scrollY > 200) {
          document.getElementById("header_top").classList.add("fixed-head");
          const navbarHeight =
            document.querySelector(".fixed-header").offsetHeight;
          //document.body.style.paddingTop = navbarHeight + "px";
        } else {
          document.getElementById("header_top").classList.remove("fixed-head");
          //document.body.style.paddingTop = "0";
        }
      };

      window.addEventListener("scroll", handleScroll);

      return () => {
        window.removeEventListener("scroll", handleScroll);
      };
    }
  }, []);
  const quickviewmodal = () => {
    contextValues.setToggleQuickViewModal(!contextValues.toggleQuickViewModal)
  }
  const loginModal = () => {
    contextValues.setToggleLoginModal(!contextValues.toggleLoginModal)
  }
  const menuModal = () => {
    contextValues.setToggleMenuModal(!contextValues.toggleMenuModal)
  }


  const cartSessionData = () => {
    const dataString = {
      product_id: '',
    };
    ApiService.postData("cartSessionData", dataString).then((res) => {
      if (res.data.status === "success") {
        contextValues.setCartSessionData(res.data.resCartData)
        contextValues.setCartCount(res.data.resCartData.length)
        contextValues.setCartSummary(res.data.cartSummary)
      }
    });
  }

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      if (searchTerm.length >= 2) {
        fetchSearchResults();
      }
    }, 300);
    return () => clearTimeout(delayDebounceFn);
  }, [searchTerm]);

  const getheaderdata = () => {
    ApiService.fetchData("header-data").then((res) => {
      if (res.status === "success") {
        setHeaderData(res.headerdata);
      }
    });
  };

  const getSettingsData = () => {
    ApiService.fetchData("settings").then((res) => {
      if (res.status === "success") {
        setSettingData(res.sitesettings);
        setSettingImagePath(res.setting_image_path)
      }
    });
  };

  const handleInputChange = (event) => {
    setSearchTerm(event.target.value);
  };

  const fetchSearchResults = () => {
    const dataString = {
      query: searchTerm,
    };
    ApiService.postData("getsearchdata", dataString).then((res) => {
      if (res.status === "success") {
        setSearchResults(res.data);

        // setCatid(res.resCategory.cat_id);
      } else {
      }
    });
  };
  const wrapperRef = useRef(null);

  const handleClickOutside = (event) => {
    if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
      setSearchResults([]);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);
  const [selectedCurrency, setSelectedCurrency] = useState('INR');

  const handleChangeCurrency = () => {
    const MultiCurrencySession = localStorage.getItem("MULTI_CURRENCY");
    const parsedMultiCurrencySession = MultiCurrencySession ? JSON.parse(MultiCurrencySession) : {};
    let selectedValue = '';

    if (parsedMultiCurrencySession) {
      selectedValue = parsedMultiCurrencySession.cr_currency_select;
    } else {
      selectedValue = selectedCurrency;
    }

    const dataString = {
      selectedValue: selectedValue,
    };
    setSpinnerLoading(true);
    ApiService.postData("currency-rates", dataString).then((res) => {
      if (res.status === "success") {
        setSelectedCurrency(selectedValue);
        localStorage.setItem('MULTI_CURRENCY', JSON.stringify(res.rowCurrencyRates));
        setSpinnerLoading(false);
      } else {
        setSpinnerLoading(false);
      }
    });
  };

  const handleChange = (event) => {
    const MultiCurrencySession = localStorage.getItem("MULTI_CURRENCY");
    const parsedMultiCurrencySession = MultiCurrencySession ? JSON.parse(MultiCurrencySession) : {};
    let selectedValue = '';
    if (event) {
      selectedValue = event.target.value;
    } else {
      if (parsedMultiCurrencySession) {
        selectedValue = parsedMultiCurrencySession.cr_currency_select;
      } else {
        selectedValue = selectedCurrency;
      }
    }
    const dataString = {
      selectedValue: selectedValue,
    };
    setSpinnerLoading(true);
    ApiService.postData("currency-rates", dataString).then((res) => {
      if (res.status === "success") {
        setSelectedCurrency(selectedValue);
        localStorage.setItem('MULTI_CURRENCY', JSON.stringify(res.rowCurrencyRates));
        setSpinnerLoading(false);
        window.location.reload();
      } else {
        setSpinnerLoading(false);
      }
    });
  };
  const cartModal = () => {
    contextValues.setToggleCartModal(!contextValues.toggleCartModal)
  }


  return (
    <>
   
      <BrowserView>
        {headerdata.header_top ? (
          <div className="top-header">
            <Container>
              <div className="row justify-content-center align-items-center">
                <div className="col-lg-6" style={{ color: "#fff" }}>
                  <marquee>{headerdata.header_top}</marquee>
                </div>
                <div className="col-lg-6" >
                  <div className="header-social">
                    <ul>
                      {settingData.facebook_url != null ? (
                        <li>
                          <a href={settingData.facebook_url} target="new">
                            <i className="fab fa-facebook-f"></i>
                          </a>
                        </li>
                      ) : (
                        ""
                      )}
                      {settingData.twitter_url != null ? (
                        <li>
                          <a href={settingData.twitter_url} target="new">
                            <i className="fab fa-twitter"></i>
                          </a>
                        </li>
                      ) : (
                        ""
                      )}
                      {settingData.pinterest_url != null ? (
                        <li>
                          <a href={settingData.pinterest_url} target="new">
                            <i className="fab fa-pinterest"></i>
                          </a>
                        </li>
                      ) : (
                        ""
                      )}
                      {settingData.instagram_url != null ? (
                        <li>
                          <a href={settingData.instagram_url} target="new">
                            <i className="fab fa-instagram"></i>
                          </a>
                        </li>
                      ) : (
                        ""
                      )}
                    </ul>
                  </div>
                </div>
              </div>
            </Container>
          </div>
        ) : null}
        <header
          className={
            state == "" ? "header fixed-header" : "header fixed-header " + state
          }
          id="header_top"
        >
          <Container>
            <div className="header-left">
              <a href="/" className="logo">
                <img
                  src={settingData.logo != null ? settingImagePath + settingData.logo : "/img/logo.png"} alt={settingData.logo}
                  width="200"
                  height="39"
                />
              </a>
            </div>
            <HeaderMenu />
            <div className="header-right justify-content-end">
              {settingData.admin_multi_currency_status === 1 ? <div className="curreny-header">
                <div className="curreny-header-select">
                  <select value={selectedCurrency} onChange={handleChange}>
                    <option value="INR">INR</option>
                    <option value="USD">USD</option>
                  </select>
                </div>
              </div> : null}
              <div className="dheader-search">
                <a className="search-link" href="javascript:void(0)" title="search">
                  <i className="d-icon-search"></i>
                </a>
                <div className="dheader-search-form">
                  <div className="dsf-inner">
                    <input type="text" placeholder="Search for products, categories & more..." onChange={handleInputChange} autoComplete="off" value={searchTerm}></input>
                    <i className="d-icon-search"></i>
                  </div>
                  {
                    searchResults && searchResults.length > 0 && searchTerm.trim() !== '' ?
                      <ul>{
                        searchResults.map((value, index) => (
                          <a href={value.redirect}>
                            <li key={index}>{value.name}</li>
                          </a>
                        ))
                      }
                      </ul>
                      : ''
                  }
                </div>
              </div>
              {setSession ? (
                <>
                  <a
                    className="login-link"
                    href="/account/account-overview"
                    title="login"
                  >
                    <i className="d-icon-user"></i>
                  </a>
                  <a
                    className="wishlist"
                    href="/account/wishlist"
                    title="wishlist"
                  >
                    <i className="d-icon-heart"></i>
                  </a>
                </>
              ) : (
                <>
                  <a
                    className="login-link"
                    href="javascript:void(0)"
                    title="login"
                    onClick={(e) => loginModal()}
                  >
                    <i className="d-icon-user"></i>
                  </a>
                  <a
                    className="wishlist"
                    href="javascript:void(0)"
                    title="wishlist"
                    onClick={(e) => loginModal()}
                  >
                    <i className="d-icon-heart"></i>
                  </a>
                </>
              )}
              <a
                className="cart-toggle"
                href="javascript:void(0)"
                title="cart"
                onClick={(e) => cartModal()}
              >
                <i className="d-icon-bag"></i>
                <span className="cart-count">{contextValues.cartCount}</span>
              </a>
            </div>
          </Container>
        </header>
      </BrowserView>
      <MobileView>
        {headerdata.header_top ? (
          <div className="top-header">
            <Container>
              <div className="row justify-content-center align-items-center">
                <div className="col-lg-12" style={{ color: "#fff" }}>
                  <marquee>{headerdata.header_top}</marquee>
                </div>
              </div>
            </Container>
          </div>
        ) : null}
        <header className="mheader d-flex">
          <div className="mheader-left">
            <a
              href="javascript:void(0)"
              className="sidenav-trigger"
              onClick={menuModal}
            >
              <i className="d-icon-bars2"></i>
            </a>
            <a href="/" className="mlogo">
              <img
                src={settingData.logo != null ? settingImagePath + settingData.logo : "/img/logo.png"} alt={settingData.logo}
                width="125"
                height="25"
              />
            </a>
          </div>
          <div className="mheader-right">
            {settingData.admin_multi_currency_status === 1 ?
              <div className="curreny-header">
                <div className="curreny-header-select">
                  <select value={selectedCurrency} onChange={handleChange}>
                    <option value="INR">INR</option>
                    <option value="USD">USD</option>
                  </select>
                </div>
              </div>
              : null}
            <a className="search-link" href="/search" title="search">
              <i className="d-icon-search"></i>
            </a>
            {setSession ? (
              <>
                <a
                  className="login-link"
                  href="/account/account-overview"
                  title="login"
                >
                  <i className="d-icon-user"></i>
                </a>
              </>
            ) : (
              <>
                <a
                  className="login-link"
                  href="javascript:void(0)"
                  title="login"
                  onClick={loginModal}
                >
                  <i className="d-icon-user"></i>
                </a>
              </>
            )}
            <a
              className="cart-toggle"
              href="javascript:void(0)"
              title="cart"
              onClick={(e) => cartModal()}
            >
              <i className="d-icon-bag"></i>
              <span className="cart-count">{contextValues.cartCount}</span>
            </a>
          </div>
        </header>
      </MobileView>
      <Modal show={contextValues.toggleQuickViewModal} onHide={(e) => quickviewmodal()} className="quickViewModal">
        {contextValues.toggleQuickViewModal && (<QuickViewModal />)}
      </Modal>
      <Modal show={contextValues.toggleMenuModal} onHide={(e) => menuModal()} className="left menu-modal">
        {contextValues.toggleMenuModal && (<MenuModal />)}
      </Modal>
      <LoginModal />
      <Modal show={contextValues.toggleCartModal} onHide={(e) => cartModal()} className="right cart-modal">
        {contextValues.toggleCartModal && (<CartModal />)}
      </Modal>
    </>
  );
}
export default Header;
