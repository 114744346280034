import { useLocation } from "react-router-dom"
import constant from "../Services/constant"
import { useContext } from "react"
import DataContext from "../Elements/eventContext"
import { useState, useEffect, useRef } from "react"

const BottomFooter = () => {
    const [accountStatus, SetAccountStatus] = useState(false)
    const didMountRef = useRef(true);
    useEffect(() => {
        if (didMountRef.current) {
          const currentPath = location.pathname;
          const isAccountOverview = currentPath.includes("/account/account-overview");
          const isAddressPage = currentPath.includes("/account/address");
          const isWishlistPage = currentPath.includes("/account/wishlist");
          const ishelpandsupportPage = currentPath.includes("/account/help-and-support");
          const isaboutusPage = currentPath.includes("/account/about-us");
    
          if (isAccountOverview || isWishlistPage || isAddressPage || ishelpandsupportPage || isaboutusPage) {
            SetAccountStatus(true)
          } else {
            SetAccountStatus(false)
          }
        }
        didMountRef.current = false;
      }, []);
    const contextValues= useContext(DataContext)
    const setSession = localStorage.getItem('USER_TOKEN')
    const location = useLocation()
    const loginModal = () => {
        contextValues.setToggleLoginModal(!contextValues.toggleLoginModal)
      }
    return (<>
        <div className="appBottomMenu">

            <a href="/" className={location.pathname === "/" ? "item active" : "item"}><div className="col"><i className="d-icon-home"></i><span>Home</span></div>
            </a>
            <a href="/category" className={location.pathname.includes("/category") ? "item active" : "item"}>

                <div className="col">
                    <i className="d-icon-layer"></i>
                    <span>Categories</span>
                </div>
            </a>
            {setSession ?
                <a href="/account/orders" className={location.pathname.includes("/account/orders") ? "item active" : "item"}>

                    <div className="col">
                        <i className="d-icon-truck"></i>
                        <span>Orders</span>
                    </div>
                </a> :
                <a href="javascript:void(0)" className="item" onClick={loginModal}>
                    <div className="col">
                        <i className="d-icon-truck"></i>
                        <span>Orders</span>
                    </div>
                </a>}
            <a href="/cart" className={location.pathname.includes("/cart") ? "item active" : "item"}>
                <div className="col">
                    <i className="d-icon-bag"></i>
                    <span>Cart</span>
                </div>
            </a>
            {setSession ?
                <a href="/account/account-overview" className={accountStatus ? "item active" : "item"}>
                    <div className="col">
                        <i className="d-icon-user"></i>
                        <span>Account</span>
                    </div>
                </a> : <a href="javascript:void(0)" className="item" onClick={loginModal}>
                    <div className="col">
                        <i className="d-icon-user"></i>
                        <span>Account</span>
                    </div>
                </a>
            }

        </div>
    </>)
}

export default BottomFooter