import React, { useEffect, useRef, useState } from "react";

function BlogsDetailsSidebar({ categoryData }) {
  const handleClick=(item)=>{
    localStorage.setItem('blogCat', item?.category_slug)
    window.location.href=`/blogs/category/${item?.category_slug}`
  }
  return (
    <>
      {categoryData && categoryData?.length > 0 && (
        <div>
          <h3>Categories</h3>
          <ul>
            {categoryData?.map((item, index)=>{
              return(<>
               <li key={index} onClick={()=>{handleClick(item)}}>{item?.category_name}</li>
              
              </>)
            })}
           
          </ul>

        </div>

      )}

    </>
  )
}
export default BlogsDetailsSidebar